import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { navigate } from '@reach/router'
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

const HeroHomeNewUpdate = ({ agentPage }) => {
  const data = useStaticQuery(graphql`
    query HeroHomeNewUpdateQuery {
      prismicHomePage {
        data {
          hero_subtitle
          button_name_1
          button_name_2
          hero_subtitle_agent
          hero_logo {
            gatsbyImageData(
              width: 307
            )
          }
          hero_background_image {
            gatsbyImageData(
              width: 1920
            )
          }
        }
      }
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const doc = data.prismicHomePage
  const heroLogo = getImage(doc.data.hero_logo)
  const heroBgImage = getImage(doc.data.hero_background_image)
  const heroBgPlaceholder = getImage(data.imgDefault)

  return (
    <>
      <div className="relative md:pt-14 xl:pt-20" style={{ display: "grid", background: "#423E42" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            objectFit: "cover",
            opacity: "0.7",
            height: 800,
          }}
          alt="hero image"
          image={heroBgImage ? heroBgImage : heroBgPlaceholder}
        />
        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2 -mt-12">
            <div className="flex justify-center">
              <GatsbyImage
                image={heroLogo ? heroLogo : null}
                alt="hero image"
                className="w-2/3 md:w-full mx-auto"
              />
            </div>

            <h2 className="text-white text-5xl md:text-6xl font-extrabold text-center uppercase mt-4">
              Weather The Storm<sup>&reg;</sup>
            </h2>

            <h3 className="max-w-xl text-white text-xl md:text-2xl font-bold text-center mt-6">
              {agentPage
                ?
                doc.data.hero_subtitle_agent
                :
                doc.data.hero_subtitle
              }
            </h3>
            <div className="flex flex-col items-center justify-center mt-6">
              {agentPage
                ?
                <button
                  onClick={() => {navigate('#sra-plans')}}
                  className="w-full md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3 mb-6"
                >
                  {doc.data.button_name_1 ? doc.data.button_name_1 : "Button Name"}
                </button>
                :
                <>
                  <div className="hidden xl:flex flex-col items-center justify-center">
                    <Link to="/qualify">
                      <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-14 py-3 mb-6">
                        <span className="default-text">Do You Qualify?</span>
                        <span className="hover-text mx-7">Begin Now</span>
                      </button>
                    </Link>
                  </div>

                  <div className="block xl:hidden mb-6">
                    <p className="text-white text-2xl font-semibold text-center tracking-wider mb-6">Do You Qualify?</p>
                    <Link to="/qualify">
                      <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-20 py-3">
                        Begin Now
                      </button>
                    </Link>
                  </div>
                </>
              }

              <Link to="/testimonials">
                <button className="w-full mb-4 xl:mb-0 md:w-auto bg-white opacity-70 hover:opacity-90 text-base md:text-lg text-site-red uppercase tracking-wider px-9 py-3">
                  Watch testimonials
                </button>
              </Link>
            </div>
            <div className="absolute bottom-0 mb-12 flex justify-center">
              <StaticImage
                src="../../images/sections/home-hero-arrow-down.png"
                width={98}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="arrow down"
                className="w-2/3 md:w-full mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

HeroHomeNewUpdate.propTypes = {
  agentPage: PropTypes.bool,
}

export default HeroHomeNewUpdate

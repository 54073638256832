import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import IntroHome from "../components/sections/intro-home"
import PlansSection from "../components/sections/plans-section"
import SectionMission from "../components/sections/section-mission"
import SectionMissionMobile from "../components/sections/section-mission-mobile"
import HeroHomeNewUpdate from "../components/sections/hero-home-new-update"

const IndexPage = () => (
  <Layout agentPage isHomePage>
    <Seo 
      title="SRA 831(b) for Financial Professionals" 
      description="Tax deferral today to address tomorrow’s risks. Strengthen your client’s business with an 831(b) Plan."
      noIndex
    />

    <HeroHomeNewUpdate agentPage />
    <IntroHome agentPage />
    <SectionMission />
    <SectionMissionMobile />
    <PlansSection />
  </Layout>
)

export default IndexPage
